.VerticalFooterContainer {
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
}

.HorizontalFooterContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    background-color: #ffffff;
}

.HorizontalFooterContainer .FooterItem {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: 15px;
    padding: 0.5rem 0.5rem;
    color: #1f3b5e;
    display: flex;
    height: 40px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-right: 1px solid #8a8a8a;
    flex-grow: 1;
}

.HorizontalFooterContainer .FooterItem:nth-of-type(1) {
    flex-grow: 2;
}

.HorizontalFooterContainer .FooterItem:nth-of-type(5) {
    border-right: none;
    flex-grow: 2;
}

.HorizontalFooterContainer .FooterItem:first-of-type {
    justify-content: flex-end;
    padding-right: 24px;
}

.HorizontalFooterContainer .FooterItem:last-of-type {
    justify-content: flex-start;
    padding: 16px;
}

.VerticalFooterContainer .FooterItem {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: 15px;
    padding: 0.75rem 0;
    color: #1f3b5e;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-bottom: 1px solid #8a8a8a;
}

.FooterItem > div {
    padding: 0.25rem 1rem;
}

.Copyright {
    background-color: #1f3b5e;
    font-size: 11px;
    font-weight: 500;
    padding: 0.5rem 1rem;
    color: rgba(255, 255, 255, 0.17);
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.Copyright > div {
    padding: 0 0.5rem;
}

.FooterItem > a {
    text-decoration: none;
    color: #1f3b5e;
}

.FooterItem i {
    font-size: 24px;
    color: #003e7e;
    margin: 0.25rem;
}

footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}
