.MobileMenuContainer {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    z-index: 5;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0.5rem;
}

.MobileMenuContainer a {
    color: #676767;
    text-decoration: none;
}

.MobileMenuSection {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    max-height: 94vh;
    overflow-y: scroll;
}

.MobileMenuParent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    color: #1f3b5e;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    box-shadow: inset 0 -1px 0 0 #d6dbe0;
    text-transform: uppercase;
}

.MobileMenuParent_Active {
    background-color: #f5f8fa;
}

.MobileMenuParent i {
    margin-top: 0.25rem;
    color: #003e7e;
    font-size: 14px;
}

.MobileMenuSecond {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    color: #1f3b5e;
    font-family: "Roboto Condensed";
    font-size: 16px;
    background-color: #f5f8fa;
    padding: 0.5rem 1rem;
    box-shadow: inset 0 -1px 0 0 #d6dbe0;
}

.MobileMenuSecond_Active {
    font-weight: bold;
}

.MobileMenuSecond i {
    color: #54667e;
    margin-top: 0.25rem;
    font-size: 14px;
}

.MobileMenuThird {
    width: 100%;
    color: #031d40;
    font-family: "Roboto Condensed";
    font-size: 14px;
    background-color: #f5f8fa;
    padding: 0.5rem 1rem 0.5rem 1.75rem;
    box-shadow: inset 0 -1px 0 0 #d6dbe0;
}

.MobileMenuThird i {
    display: none;
}

.MobileMenuThird:hover i,
.MobileMenuThird:active i {
    font-size: 11px;
    color: #055eba;
    vertical-align: center;
    display: inline-block;
    padding: 0 0 0 0.5rem;
}
