.Container {
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  height: 150px;
  z-index: 1;
}

.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.Shade {
  background-color: rgba(0, 0, 0, 0.42);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.Heading {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: regular;
  color: #ffffff;
  font-size: 43px;
  margin: 1rem 0 0 0;
  z-index: 2;
  margin: 0 2rem;
}

.MobileHeading {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: regular;
  color: #ffffff;
  font-size: 28px;
  margin: 1rem 0 0 0;
  z-index: 2;
  margin: 1rem 2rem;
}

.ContentHeaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.MobileContentHeaderContainer {
  display: flex;
  flex-flow: column nowrap;
  height: 226px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.MobileLogo {
  margin: 1rem 0;
  max-width: 160px;
}

.Logo {
  margin: 0 2rem;
  max-width: 160px;
}

.HorizontalSpacer {
  height: 1rem;
  width: 60%;
  margin: 0 1rem;
  border-bottom: 1px solid #fff;
}

.VerticalSpacer {
  height: 60%;
  width: 1rem;
  margin: 0 1rem;
  border-right: 1px solid #fff;
}
