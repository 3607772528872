@keyframes circle1 {
    0% {transform: none; fill-opacity: 1;}
    50% {transform: translate(6px, 6px) scale(0.6); fill-opacity: 1;}
    100% {transform: none; width: 100%; height: 100%; fill-opacity: 1;}
}

@keyframes circle2 {
    0% {transform: none; fill-opacity: 1;}
    50% {transform: translate(-40px, -10px) scale(1.666666666667); fill-opacity: 1;}
    100% {transform: none; width: 100%; height: 100%; fill-opacity: 1;}
}

@keyframes circle3 {
    0% {transform: none; fill-opacity: 1;}
    50% {transform: translate(42px, 6px) scale(0.6); fill-opacity: 1;}
    100% {transform: none; width: 100%; height: 100%; fill-opacity: 1;}
}

.svg-circle-3 {
    animation: circle3 0.8s infinite;
}

.svg-circle-2 {
    animation: circle2 0.8s infinite;

}

.svg-circle-1 {
    animation: circle1 0.8s infinite;
}