.Container {
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    height: 150px;
}

.BackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 100%;
    height: 150px;
    z-index: 0;
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.Shade {
    background-color: rgba(0, 0, 0, 0.42);
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 100%;
    z-index: 1;
}

.Heading {
    font-family: "Roboto Condensed", sans-serif;
    color: #ffffff;
    font-size: 43px;
    position: relative;
    top: 1rem;
    left: 0;
    z-index: 2;
    padding: 0 1rem;
}

.SubHeading {
    font-size: 17px;
    align-items: flex-start;
    color: #ffffff;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    position: relative;
    bottom: 1rem;
    left: 0;
    z-index: 2;
    padding: 0 1rem;
}
