.AccountDropdownMenuContainer {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    width: 200px;
    z-index: 6;
    box-shadow: 0 4px 5px 0 rgba(3, 29, 64, 0.14), 0 1px 10px 0 rgba(3, 29, 64, 0.12), 0 2px 4px -1px rgba(3, 29, 64, 0.2);
}

.PlayerDropdownMenuContainer {
    position: absolute;
    top: 40px;
    left: 84px;
    background-color: #fff;
    width: 210px;
    z-index: 6;
    box-shadow: 0 4px 5px 0 rgba(3, 29, 64, 0.14), 0 1px 10px 0 rgba(3, 29, 64, 0.12), 0 2px 4px -1px rgba(3, 29, 64, 0.2);
    max-height: 180px;
    overflow-y: scroll;
}

ul {
    list-style-type: none;
}

li {
    padding: 0.5rem;
    color: #676767;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.AccountDropdownMenuContainer ul,
.AccountDropdownMenuContainer ul,
.PlayerDropdownMenuContainer ul {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.NavHeader {
    cursor: default;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #1f3b5e;
    font-size: 14px;
    text-transform: uppercase;
}

.PlayerName {
    padding: 0;
}

.PlayerName i {
    display: none;
}

.PlayerName:hover i {
    font-size: 11px;
    color: #055eba;
    vertical-align: center;
    display: inline-block;
    padding: 0 0 0 0.5rem;
}

.PlayerName:hover {
    background-color: #f5f8fa;
}

.PlayerName > a,
.PlayerName > .LogoutItem {
    color: #676767;
    cursor: pointer;
    display: block;
    padding: 0.5rem;
}
