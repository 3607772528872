.CardHeaderRow {
    background-color: #003e7e;
    padding: 0.5rem 1rem;
    width: 100%;
    display: grid;
    align-items: center;
}

.CardIcon {
    color: #b2ddff;
    font-size: 16px;
    margin: 0 0.5rem 0 -0.25rem;
}

.CardText {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.ChevronIcon {
    font-size: 16px;
    margin: 0 0.5rem;
    color: #b2ddff;
}
