.MegaMenuContainer {
  position: absolute;
  top: 51px;
  left: 0;
  background-color: #fff;
  z-index: 4;
  display: grid;
  grid-template-columns: 250px 275px 245px 235px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  text-transform: none;
  font-weight: 200;
  box-shadow: 0 4px 5px 0 rgba(3, 29, 64, 0.14), 0 1px 10px 0 rgba(3, 29, 64, 0.12), 0 2px 4px -1px rgba(3, 29, 64, 0.2);
}

.ColumnHeader {
  font-weight: bold;
  color: #1f3b5e;
  text-transform: uppercase;
}

.MenuColumn {
  padding: 1.5rem;
}

.MegaMenuContainer p {
  padding: 0;
  margin: 4px 0;
}

.MegaMenuContainer a {
  color: #676767;
  text-decoration: none;
}

.MissingLink {
  background-color: yellow;
}
.Link i {
  display: none;
}
.Link:hover i {
  font-size: 11px;
  color: #055eba;
  vertical-align: center;
  display: inline-block;
  padding: 0 0 0 0.5rem;
}

.Link:hover {
  background-color: #f5f8fa;
}

.Link:focus {
  background-color: #f5f8fa;
}
