.ShadowCard {
  border: 0.25px solid #d6dbe0;
  min-height: 100px;
  margin: 1rem 1rem;
}

.EventItem {
  margin: 1rem 0;
  padding: 1rem;
  border: 0.25px solid #d6dbe0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 1rem);
}

.CardContainer:nth-of-type(odd) {
  padding: 0 1rem;
}
.CardContainer:nth-of-type(even) {
  padding: 0 1rem 0 0;
}

.TourTagR {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #013dc7;
}
.TourTagH {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #026150;
}
.TourTagS {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #7b020e;
}
.TourTagC {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #707070;
}
.TourTagM {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #060388;
}

.TourLogo {
  height: 145px;
  margin: 0 1rem 0 0;
}
.EventName {
  font-size: 25px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  margin: 0;
  color: #000;
}
.EventLinks {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}
.EventLink {
  margin: 0;
  padding: 0.25rem 0.75rem 0.25rem 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
.EventLink a {
  color: #003e7e;
  text-decoration: underline;
}
.EventLink i {
  color: #003e7e;
  font-size: 12px;
  padding: 0 0.25rem;
}
.EventDates {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  color: #464646;
}

.ContactInformation > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  margin: 1rem 0;
  padding: 1rem 0;
}

.ContactInformation > div p {
  margin: 0.25rem 1rem 0.25rem 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
.CommitmentInfo {
  display: flex;
  flex-flow: row wrap;
}

.CommitmentInfo > div {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 1rem 0 0;
  width: 180px;
  font-size: 14px;
}

.CommitmentInfo > div > strong {
  font-family: "Roboto", sans-serif;
  font-weight: medium;
  color: #3c3c3c;
  font-size: 14px;
}

.CommitmentsInformation {
  color: green;
}
