.AdminHeader {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
    color: #1f3b5e;
}

.AdminContainer h2 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-left: 4px;
    text-transform: uppercase;
}

.AdminContainer input {
    border: 1px solid black;
    border-radius: 4px;
    font-size: 22px;
    height: 50px;
    margin: 0 1rem 0 2rem;
    padding-left: 8px;

}

.AdminContainer input::placeholder {
    color: lightgrey;
    text-transform: capitalize;
}

.AdminContainer button {
    border: 1px solid #1f3b5e;
    border-radius: 4px;
    font-family: "Roboto Condensed Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    height: 50px;
    padding: 10px;
    text-transform: uppercase;
}

.AdminContainer button:hover {
    background-color: #f7f7f7;
    cursor: pointer;
}

.SelectedPlayerOrAgent {
    font-size: 1.25rem;
    margin-left: 2rem;
    margin-top: 1rem;
}

.Green {
    color: green;
}

.Red {
    color: red;
}

.AdminContainer .CheckBtn {
    margin-left: 12px;
    padding-bottom: 5px;
}

.OverrideBtn {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.AdminBtn {
    background-color: white;
    color: #40a9ff;
    border-color: #676767;
    margin: 0 1rem 0 1rem;
    width: 140px;
    position: relative;
    bottom: 2px;
}

.FormDiv {
    margin-bottom: 3rem;
}

.AdminBtn:disabled {
    opacity: 0.5;
}

.AdminBtn:disabled:hover {
    background-color: white;
    cursor: default;
    opacity: 0.5;
}

.AdminBtnGroup {
    display: inline;
}

@media screen and (max-width: 830px){
    .FormItemDiv input, .AdminBtnGroup button {
        width: calc(100% - 2rem);
        margin: 8px 16px 8px 24px;
    }

    .AdminBtnGroup {
        display: block;
        margin-top: 1rem;
    }

    .AdminBtnGroup button {
        display: block;
    }
}


