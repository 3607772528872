.ContentContainer {
    padding: 0;
    margin: 0;
    display: grid;
    font-family: "Roboto", sans-serif;
    background-color: #eaeef0;
    overflow-x: hidden;
}

.PageContent {
    padding: 1rem;
}

.AnchorLinks {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-items: flex-start;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #1f3b5e;
    text-transform: uppercase;
}

.AnchorLinksHeader {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #1f3b5e;
    padding: 17px 1rem 1rem 1rem;
    background-color: rgba(208, 234, 255, 0.17);
    border-right: 1px solid #d6dbe0;
}

.ContentContainer a {
    color: #055eba;
}

.Divider {
    width: 100%;
    margin: 0.5rem 1rem;
    height: 3px;
    background-color: #707070;
}

.UpcomingTournaments table td {
    border: 1px solid black !important;
}
