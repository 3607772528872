.NavigationContainer {
  background-color: white;
  height: 100%;
  margin: 0;
  width: 100%;
}

.TopContainer {
  background-color: #1f3b5e;
  display: grid;
  justify-items: center;
  position: relative;
  z-index: 7;
}

.NavigationItem {
  color: white;
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
}

.NavigationItem > a:focus {
  border: none !important;
}

.NavigationItem i {
  font-size: 20px;
  color: #b2ddff;
}

.BottomContainer {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(5, fit-content(400px));
  align-items: center;
  justify-items: stretch;
  z-index: 5;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  position: relative;
}

.NavigationItemB {
  color: #1f3b5e;
  font-size: 15px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.875em 0 0.875rem 1.5rem;
  cursor: pointer;
}

i.ArrowIcon {
  color: #b2ddff;
  font-size: 12px;
  padding: 0 0.25rem;
}

i.ArrowIconSecond {
  color: #1f3b5e;
  font-size: 14px;
  padding: 0 0.25rem;
}

.NavigationItem > a {
  color: white;
}

.NavigationItemB > a {
  color: #1f3b5e;
}

.NavigationItem:active {
  background-color: #031d40;
}

.NavigationItem_ActivePGA {
  height: 4px;
  width: 100%;
  background-color: #055eba;
  margin: 0.5rem 0 -1rem 0;
}
.NavigationItem_ActiveCHAMP {
  height: 4px;
  width: 100%;
  background-color: #7b020e;
  margin: 0.5rem 0 -1rem 0;
}
.NavigationItem_ActiveKF {
  height: 4px;
  width: 100%;
  background-color: #026150;
  margin: 0.5rem 0 -1rem 0;
}
.NavigationItem_ActivePGAC {
  height: 4px;
  width: 100%;
  background-color: #707070;
  margin: 0.5rem 0 -1rem 0;
}
.NavigationItem_ActivePTLA {
  height: 4px;
  width: 100%;
  background-color: #060388;
  margin: 0.5rem 0 -1rem 0;
}

.TopNav_Active {
  background-color: #031d40;
}

.small-icon {
  font-size: 12px;
}
