.MainContainer {
    width: 100%;
    background-color: #eaeef0;
    height: 100%;
}

.EndorsementsContainer {
    font-family: "Roboto", sans-serif;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    color: #1f3b5e;
}

.EndorsementsContainer h1 {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #1f3b5e;
}

.EndorsementsContainer h2 {
    font-size: 25px;
    font-weight: normal;
    color: #1f3b5e;
}

.EndorsementItems {
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.EndorsementItems div {
    background-color: #fff;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-top: 1px solid #eaeef0;
    border-bottom: 1px solid #eaeef0 ;
    padding: .5rem 1rem;
}

.InstructionText {
    text-align: center;
    font-size: 14px;
    color: #000;
    margin-bottom: 1.25rem;
}

.InstructionText p {
    margin-bottom: 0.125rem;
}

.EndorsementsContainer .AreaCategoryLabel {
    border: 1px solid #cdd2d4;
    background: #dfe5e8;
    color: #676767;
    cursor: pointer;
    padding: .25rem 1rem;
    text-transform: uppercase;
}

.EndorsementsContainer .AreaCategoryLabel i {
    margin-top: auto;
    margin-bottom: auto;
}

.EndorsementsContainer a:active,
.EndorsementsContainer a:focus {
    outline: none;
    border: none;
}

.EndorsementsContainer span i {
    color: #b2ddff;
    cursor: pointer;
}

.VerifyText {
    color: red;
    margin-top: .5rem;
}

.VerifiedText {
    color: green;
    margin-top: .5rem;
}

.VerifyButtonWrapper {
    text-align: center;
}

.VerifyButton {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    min-width: 104px;
    justify-self: center;
    align-self: center;
    border: none;
    padding: 10px;
    background-color: #1f3b5e;
    color: #fff;
    font-family: "Roboto Condensed Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 1px 2px rgb(0, 0, 0, .2);
}
