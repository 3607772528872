.TermsAndConditions {
    font-family: "Roboto Condensed", sans-serif;
    margin-top: 1rem;
    padding: 0 3rem;
}

.TermsAndConditions h1 {
    color: #1f3b5e;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2rem;
}

.TermsAndConditions ol li {
    font-size: 1rem;
}

.TermsAndConditions li ul li {
    list-style-type: initial;
}