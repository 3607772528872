.SectionContainer {
    margin: 0 1rem 32px 1rem;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(3, 29, 64, 0.14), 0 2px 1px -1px rgba(3, 29, 64, 0.12), 0 1px 3px 0 rgba(3, 29, 64, 0.2);
    background-color: #ffffff;
}

.EventsContainer {
    display: flex;
    flex-flow: column nowrap;
}

.NoData {
    padding: 1rem;
    font-size: 14px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
}
/* Announcements */
.AnnouncementsContainer {
    max-height: 500px;
    overflow-y: scroll;
}

.AnnouncementCard strong {
    font-weight: normal;
}

.AnnouncementCard h4 br {
    width: 100%;
    height: 1px;
    background-color: red;
}

.AnnouncementCard h4,
.AnnouncementCard p {
    padding-left: 0.75rem;
}

.AnnouncementCard h4 {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
}
.AnnouncementCard p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

.AnnouncementCard a {
    color: #055eba;
    text-decoration: underline;
}

.MobileSectionMenu {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
}

.AnnouncementMenuParent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    color: #1f1f1f;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: normal;
    padding: 0.5rem 1rem;
    box-shadow: inset 0 -1px 0 0 #d6dbe0;
    text-transform: uppercase;
}

.AnnouncementMenuParent i {
    color: #055eba;
    font-size: 11px;
    margin-top: 0.25rem;
}

.AnnouncementMenuParent_Active {
    font-weight: bold !important;
}

.AnnouncementMenuContent {
    padding: 0.5rem 1rem;
}

/* Upcoming Events */
.EventContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 0 0 1rem 0;
}

.Reports {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.ReportAdditionalInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ReportItem {
    margin: 1rem;
    flex-grow: 2;
    padding: 1rem;
    border: 1px solid #d6dbe0;
    background: linear-gradient(180deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 100%);
    cursor: pointer;
}


.ReportItem h2 {
    color: #000000;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 25px;
    margin: 0;
    padding: 0;
}

.ReportItem h2 > img {
    max-height: 30px;
    padding: 0 0.25rem;
}

.ReportLinks {
    color: #003e7e;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

.ReportLinks a {
    color: #003e7e;
    font-family: Roboto;
    font-size: 12px;
    text-decoration: underline;
}

.ReportLinks i {
    font-size: 10px;
}

@media screen and (max-width: 767px) {
    .ReportAdditionalInfo {
        display: grid;
        grid-template-columns: 1fr;
    }
}
