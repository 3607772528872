.ModalTitle {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #013d7c !important;
}

.ModalContent p {
    font-family: "Roboto", sans-serif;
}

.ModalContent p a {
    color: #013d7c;
}

.ModalContent li {
    color: inherit;
}

