.SiteMapContainer,
.MainContainer {
    height: 100%;
    width: 100%;
    background-color: #eaeef0;
}

.SiteMapContainer h1 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #1f3b5e;
    text-transform: uppercase;
    text-align: center;
    padding-top: 0.5rem;
}

.SectionRow {
    background-color: #fff;
    padding: 1rem;
    margin: 1rem;
}

.SectionRow:nth-of-type(even) {
    background-color: #b2ddff;
}

.SectionRow h2 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #1f3b5e;
    text-transform: uppercase;
}
.SectionRow a {
    color: #1f3b5e;
    padding: 1rem 1rem 1rem 0;
}

.NavigationContainer {
    display: flex;
    flex-flow: row nowrap;
}

.NavigationColumn {
    display: flex;
    flex-flow: column nowrap;
    width: 25%;
}

.NavigationColumn a {
    padding: 0.25rem 0;
}

.ReportContainer {
    padding: 1rem;
    overflow: hidden;
}

.TeeTimeH2 {
    text-align: left !important;
}

.ReportContainer h1 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #1f3b5e;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
}

.ReportContainer h2 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #1f3b5e;
    font-size: 28px;
    text-align: center;
    margin: 1rem 0;
}
.ReportSectionRow {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    background-color: #b2ddff;
}

.HeaderContent {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #1f3b5e;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    text-align: center;
}

.PlayerName {
    font-size: 20px;
}

.TableContainer {
    overflow-x: scroll;
    padding: 0.5rem 0;
}

.Filters {
    display: flex;
    flex-flow: row nowrap;
    margin: 1rem 0;
}

.FilterLabel {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1f3b5e;
    padding: 0.5rem 1rem 0.5rem 0;
}

.FilterItem {
    padding: 0.5rem 1rem;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.FilterItem_Active {
    border-bottom: 2px solid #1f3b5e;
}

.Info {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #000;
}

.TimeRow {
    background-color: #1f3b5e;
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.Empty {
    background-color: #fff;
    color: #1f3b5e;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    padding: 1rem;
}

.HoleList {
    margin-bottom: 1.5rem;
}

.HoleLinkItem {
    padding: 0;
}

.HoleLinkItem a {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 18px;
    color: #1f3b5e;
    cursor: pointer;
}

.HoleLinkItem a:hover {
    color: #40a9ff;
}

.HoleLinkItem a:focus {
    border: none;
}

.InfoItems {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}

.InfoItem {
    background-color: #fff;
    display: flex;
    flex-flow: row wrap;
    padding: 1rem;
    margin: 1rem;
    justify-content: space-between;
    box-shadow: 0 0 4px 0 #d2d2d2;
    color: #1f3b5e;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: regular;
}

.InfoItem > div:nth-of-type(odd) {
    margin: 0 0 0 1rem;
}

.InfoItem > div:nth-of-type(even) {
    margin: 0 1rem 0 0;
}

.InstructionText {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #000;
}

.InstructionText p {
    margin-bottom: 0.125rem;
}

.BulletItems {
    background-color: #fff;
    margin: 1rem;
}

.BulletItems > ul {
    list-style-type: disc;
    padding: 0.5rem 2rem;
}

.BulletItems > ul li {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: normal;
    color: #1f3b5e;
}

.StatementOptions {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    background-color: #1f3b5e;
    color: #fff;
}

.StatementOptions > div {
    padding: 1rem;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.StatementOptions > div.Active {
    padding: 1rem;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.NoPointer {
    cursor: default !important;
    color: #b2ddff !important;
}

.FramePlaceholder {
    width: 100%;
    border: 1px solid #000;
    min-height: 40px;
}

.FramePlaceholder > p {
    text-align: center;
    padding: 2rem;
}

.EarningsLink {
    display: block;
    font-size: 1rem;
    padding-left: 0.25rem;
}

.EarningsLink:focus {
    border: none;
}

.WarningsHead {
    background-color: #1f3b5e;
    color: white;
    font-size: 1.25rem;
    text-transform: none;
    text-align: center;
}
