.PrimaryButton {
    margin: 50px;
    min-width: 104px;
    justify-self: center;
    align-self: center;
    border: none;
    padding: 10px;
    background-color: white;
    color: #1f3b5e;
    font-family: "Roboto Condensed Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}

.SecondaryButton {
    margin: 50px;
    min-width: 104px;
    justify-self: center;
    align-self: center;
    border: none;
    padding: 10px;
    background-color: #1f3b5e;
    color: #fff;
    font-family: "Roboto Condensed Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}

.PrimaryButton_disabled {
    margin: 50px;
    min-width: 104px;
    justify-self: center;
    align-self: center;
    border: none;
    padding: 10px;
    background-color: white;
    color: #1f3b5e;
    font-family: "Roboto Condensed Bold", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0.5;
}

table button {
    cursor: pointer;
}
