.SectionContainer {
    margin: 0 1rem 12px 1rem;
    border: 1px solid #d6dbe0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(3, 29, 64, 0.14), 0 2px 1px -1px rgba(3, 29, 64, 0.12), 0 1px 3px 0 rgba(3, 29, 64, 0.2);
    background-color: #ffffff;
}

.SectionContainer:first-child {
    margin-top: 4rem;
}

.SectionContainer:last-child {
    margin-bottom: 4rem;
}

.HeaderRow {
    align-items: center;
    background-color: #003e7e;
    border: 1px solid #d6dbe0;
    display: grid;
    padding: 0.5rem 1rem;
    width: 100%;
}

.HeaderText {
    background-color: #f2f2f2;
    padding: 0.5rem 1rem;
    width: 100%;
    display: grid;
    align-items: center;
    color: #000;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
}

.Table {
    border-top: 1px solid #d6dbe0;
}

.Table tr  {
    border-bottom: none;
}


.Table tr td:first-child {
    color: #1f3b5e;
    font-weight: bold;
    text-align: right;
    vertical-align: top;
    width: 150px;
}

.SelectionInstructions {
    background-color: #d1edf7;
    border-radius: 6px;
    color: #3087a7;
    padding: 12px;
}

.SelectionInstructions h4,
.SelectionInstructions li {
    color: #3087a7;
    padding: 0;
}

.SelectionInstructions h4 {
    font-weight: bold;
}

.Table .OtherInput {
    border: 1px solid #d6dbe0;
    border-radius: 4px;
    height: 40px;
    padding: 2px 2px 2px 6px;
    width: 300px;
}

.Table .RadioLabels label {
    color: #000;
    font-weight: normal;
    padding-left: 4px;
    text-transform: none;
}

.Table label {
    text-transform: none;
}

.CreateButton {
    background-color: #1f3b5e;
    border: 1px solid #1f3b5e;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.CreateButton:hover {
    color: white;
}

.CreateButton:disabled {
    cursor: initial;
    background-color: #DADFE6;
    border-color: #DADFE6;
}

.CancelButton {
    background-color: white;
    border: 1px solid #1f3b5e;
    color: #1f3b5e;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.CancelButton:hover {
    color: #1f3b5e;
}

button:focus {
    outline: none;
}

.ViewContainer .CreateButton {
    cursor: pointer;
    margin: 1.25rem 0 1rem 1rem;
}

.FieldTable td,
.FieldTable th {
    width: 100px;
    padding-left: 0;
    padding-right: 8px;
}

.FieldTable {
    margin-top: 2rem;
    width: 100%;
}

.TeamProamTable td,
.TeamProamTable th {
    width: 100px;
}

.TeamProamTable tr:first-child > th {
    color: inherit;
}

.ConfirmedIcon i {
    color: #167f06;
    padding-left: 8px;
}
