.LoginContainer {
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}
.LoginContainer::-webkit-scrollbar {
  display: none;
}

.BackgroundImage {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -5px;
  width: 105%;
  z-index: -2;
  background-image: url(../img/PGA_LoginBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.Shade {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.42);
}

.FormData {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 682px;
}

.Logo {
  padding-top: 48px;
  padding-bottom: 32px;
}

.Label {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  color: white;
  align-self: flex-start;
}

.HeaderText {
  font-size: 43px;
  justify-self: center;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  padding-bottom: 30px;
  color: white;
}

.HeaderTextH2 {
  font-size: 32px;
  justify-self: center;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  padding-bottom: 30px;
  color: white;
}

.InputText {
  justify-self: center;
  height: 47px;
  color: #676767;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 1rem;
}
.InputText::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #676767;
  font-family: 'Roboto', sans-serif;
  opacity: 1;
}
.InputText_Password {
  justify-self: center;
  height: 47px;
  color: #676767;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}
.InputText_Password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #676767;
  font-family: 'Roboto', sans-serif;
  opacity: 1;
}
.Divider {
  grid-column: 1/4;
  padding: 0 50px;
}

.FooterContainer {
  max-width: 682px;
  width: 100%;
  margin-top: 30px;
}

.LandingLogos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px 0;
}

.FormNote {
  text-align: center;
  background-color: rgba(31, 59, 94, 0.61);
  margin-bottom: 50px;
}

.FormNote > p {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: white;
  max-width: 500px;
  margin: 0;
  padding: 0.5rem 0;
}

.LandingLogos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 682px;
  padding: 20px 0;
}

.LandingLogos > img {
  height: 85%;
}

.AlertWindow {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
