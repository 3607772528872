.MainContainer {
    height: 100%;
    width: 100%;
    background-color: #eaeef0;
}

.CommitmentStatusContainer {
    margin: 1rem;
}

.PlayerOverview {
    margin: 0 1rem 1rem 1rem;
    background-color: #ffffff;
    max-width: 100%;
    padding: 0 0 1rem 0;
    min-height: 180px;
    box-shadow: 0 1px 1px 0 rgba(3, 29, 64, 0.14), 0 2px 1px -1px rgba(3, 29, 64, 0.12), 0 1px 3px 0 rgba(3, 29, 64, 0.2);
}

.DashboardDivider {
    width: 100%;
    height: 40px;
    background-color: #1f3b5e;
}

.PlayerSelection {
    background-color: #eaeef0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #1f3b5e;
    padding: 0.5rem 1rem;
    margin: 0 0 1rem 0;
}
