.AlertWindow {
    width: 100%;
    background-color: #003e7e;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 30px auto 30px;
    grid-template-rows: repeat(2, fit-content(80px));
}

.HornIcon {
    margin: 0.25rem 0;
    padding: 0 0.25rem;
    font-size: 16px;
    color: #b2ddff;
    grid-row: 1 / -1;
    grid-column: 1 / 2;
}

.AlertMessage h4 {
    color: #b2ddff;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: medium;
    font-size: 16px;
    text-transform: uppercase;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: start;
    margin: 0.25rem 0;
}

.AlertMessage p {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: start;
    margin: 0;
}

.AlertMessage p:nth-last-of-type(1) {
    margin-bottom: 0.5rem;
}

.CloseButton {
    float: right;
    margin: 0.25rem;
    font-size: 16px;
    color: white;
    grid-column: 3 / -1;
    grid-row: 1 / 3;
}

.AlertMessage a {
    color: #b2ddff;
    text-decoration: underline;
}

.AlertWrapper {
    margin: 0 24px 12px 24px;
    min-height: 48px;
    border-radius: 2px;
    display: flex;
    flex-flow: row nowrap;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(3, 29, 64, 0.14), 0 3px 1px -2px rgba(3, 29, 64, 0.12), 0 1px 5px 0 rgba(3, 29, 64, 0.2);
}
.AlertWrapper_Small {
    margin: 0 8px;
    height: 48px;
    border-radius: 2px;
    display: flex;
    flex-flow: row nowrap;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(3, 29, 64, 0.14), 0 3px 1px -2px rgba(3, 29, 64, 0.12), 0 1px 5px 0 rgba(3, 29, 64, 0.2);
}

.AlertWrapper_Positive {
    border: 2px solid #167f06;
}

.AlertWrapper_Negative {
    border: 2px solid #cd0a0a;
}
.AlertWrapper_Title {
    border: 2px solid #013d7c;
    margin: 1rem 0;
}

.AlertWrapper_SuccessCommit {
    border: 2px solid #3087a7;
}

.AlertIcon_SuccessCommit {
    color: white;
    width: 48px;
    background-color: #3087a7;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.AlertIcon_Positive {
    width: 48px;
    background-color: #167f06;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}
.AlertIcon_Negative {
    width: 48px;
    background-color: #cd0a0a;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}
.AlertIcon_Title {
    background-color: #013d7c;
    font-family: "Roboto Bold", sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
    padding: 0.75rem 1.5rem 1rem 1.5rem;
    text-transform: uppercase;
}

.AlertIcon_Positive i,
.AlertIcon_Negative i {
    color: #fff;
    font-size: 16px;
}

.FieldText {
    color: #464646;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 12px;
    padding: 0.825rem;
    text-transform: uppercase;
}

.FieldTextInfo {
    color: #464646;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 12px;
    padding: 0.825rem;
    text-transform: uppercase;
    display: flex;
    flex-flow: row nowrap;
}

.FieldTextInfo div {
    padding: 0.5rem 1rem;
    margin: -0.5rem 0 -0.5rem 0;
    border-right: 1px solid #d8d8d8;
}

.FieldTextInfo div:nth-last-of-type(1) {
    border-right: none;
}
