* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  position: relative;
  /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";*/
}

#root {
  background-color: #eaeef0;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-bottom: 84px;
}

/* React TABS */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0 0 10px;
  background-color: #eaeef0;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border-right: 1px solid #d6dbe0;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 1rem 2rem;
  font-size: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  color: #1f1f1f;
  cursor: pointer;
  text-transform: uppercase;
}

.react-tabs .react-tabs__tab:focus,
.react-tabs .react-tabs__tab:active {
  box-shadow: none;
  border-color: #d6dbe0;
}

.react-tabs .react-tabs__tab--selected:focus::after {
  background: none;
}

.react-tabs__tab--selected {
  background-color: #eaeef0;
  font-weight: bold;
}

.react-tabs__tab--selected .upcoming {
  color: #000;
  border-bottom: 3px solid #000;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--selected .pga {
  color: #013d7c;
  border-bottom: 3px solid #013d7c;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--selected .kornferry {
  color: #026150;
  border-bottom: 3px solid #026150;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--selected .canada {
  color: #707070;
  border-bottom: 3px solid #707070;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--selected .champion {
  color: #7b020e;
  border-bottom: 3px solid #7b020e;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--selected .latinoamerica {
  color: #060388;
  border-bottom: 3px solid #060388;
  margin: 0 -1rem -1rem -1rem;
  padding: 0 1rem 1rem 1rem;
}

.react-tabs__tab--disabled {
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

/* Content Styles */

.page-title {
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  color: #1f3b5e;
  font-size: 33px;
  border-bottom: 1px solid #d6dbe0;
  margin: 1rem 0;
}

.content-section {
  padding-bottom: 32px;
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .content-section {
    padding-bottom: 32px;
    padding: 0;
  }
}

.content-section table {
  width: 98% !important;
  max-width: 98% !important;
}

a:focus {
  border: 1px solid #1f3b5e;
}

.anchor-link {
  padding: 1.25rem 1rem 0.75rem 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #055eba;
  border-right: 1px solid #d6dbe0;
}

.anchor-link a {
  color: #055eba;
}

.anchor-link i {
  padding: 0 0.25rem;
  color: #055eba;
}

.TourCodeR {
  border-top: 3px solid #013d7c !important;
}

.TourCodeH {
  border-top: 3px solid #026150 !important;
}

.TourCodeS {
  border-top: 3px solid #78020e !important;
}

.TourCodeC {
  border-top: 3px solid #707070 !important;
}

.TourCodeM {
  border-top: 3px solid #060388 !important;
}

.contact-table div .canvasRteResponsiveTable {
  max-width: 100%;
  width: 100%;
}

.contact-table div .canvasRteResponsiveTable .tableWrapper table {
  box-shadow: none;
  width: 100% !important;
}

.contact-table div .canvasRteResponsiveTable .tableWrapper table tbody tr {
  border: none;
}

.contact-table div .canvasRteResponsiveTable .tableWrapper table tbody tr td {
  max-width: 50% !important;
  padding: 0 !important;
  width: 50% !important;
  word-break: break-word;
}

p.no-data {
  padding: 1rem 1rem 0 1rem;
}

.MuiDialog-root .MuiPaper-root {
  border-radius: 0;
}

.admin-mode {
  text-align: center;
}

.admin-mode span {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid red;
  color: white;
  font-size: 1.125rem;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%);
  z-index: 1000;
}

.mui-search-create label.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}

.endorsements-modal-label-group > label {
  color: rgb(48, 135, 167);
  font-size: 16px;
}

.endorsements-modal-label-group {
  margin-bottom: 0.5rem;
}

/* adjust for mobile footer */
@media screen and (max-width: 767px) {
  #root {
    padding-bottom: 293px;
  }
}

.video-link {
  font-size: 32px;
}

.video-link:hover {
  text-decoration: underline;
}
