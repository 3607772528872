.HeaderPreAuth {
    background-color: #1f3b5e;
    padding: 1rem;
    font-size: 1.5rem;
    margin-bottom: -4rem;
}

.HeaderPreAuth span {
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    padding-left: .5rem;
}

.HeaderPreAuth a:active, .HeaderPreAuth a:focus {
    border: none;
    outline: none;
}