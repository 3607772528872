.PageContainer {
    width: 100%;
    background-color: #eaeef0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.FormContainer {
    margin: 0;
    padding: 2rem;
    height: 100%;
    width: 100%;
    max-width: 682px;
}

.Contacts {
    color: #1f3b5e;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
}

.FormContainer label {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: darkblue;
    text-transform: uppercase;
    padding-right: 1rem;
}

.ButtonContainer {
    text-align: center;
}

.ContactHeader {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
}

.ContactLabel {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

Select {
    border-radius: 2px;
    font-family: "Roboto", sans-serif;
}

input {
    border-radius: 2px;
    font-family: "Roboto", sans-serif;
}

.FormHeader {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
    color: #1f3b5e;
}

.PageContainer h2 {
    color: #1f3b5e;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.PageContainer h1 {
    margin-bottom: 0;
}

.ExternalLink {
    text-align: center;
    margin-bottom: 1rem;
}

.ExternalLink > a {
    background-color: #fff;
    color: #1f3b5e;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    padding: 1rem;
    box-shadow: 0 0 4px 0 #d2d2d2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.ExternalLink > a > img {
    height: 32px;
}

.PageContainer input,
.PageContainer select {
    border: 1px solid #ddd;
    padding: 0.25rem;
    font-family: "Roboto", sans-serif;
    color: #000;
}

.PageContainer button {
    color: #fff;
    background-color: #1f3b5e;
    padding: 0.5rem 1rem;
    border: none;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

.PageContainer table {
    margin: 1rem 0;
}

.Invalid {
    border: 1px solid red !important;
}

td > i {
    cursor: pointer;
}

.MessageSent {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 18px;
    color: #707070;
}

.Reset {
    color: #1f3b5e;
    text-decoration: underline;
    cursor: pointer;
}

.ErrorHeader {
    margin: 1rem 0;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #1f3b5e;
    text-transform: uppercase;
}

.ErrorHeader2 {
    margin: 1rem 0;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: medium;
    font-size: 26px;
    color: #1f3b5e;
}

td > .DeleteRowButton {
    background-color: transparent;
    padding: 0;
}

.DeleteRowButton i {
    color: red;
    font-size: 18px;
}

td > .DeleteRowButton:disabled {
    cursor: default;
    position: relative;
    display: inline-block;
}

td > .DeleteRowButton:disabled i {
    color: lightgray;
}

.DeleteRowButton .TooltipText {
    visibility: hidden;
    width: 120px;
    background-color: #1f1f1f;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
    bottom: 0;
    right: 40px;
}

.DeleteRowButton:disabled:hover .TooltipText {
    visibility: visible;
}

