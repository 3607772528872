.MainContainer {
    height: 100%;
    width: 100%;
    background-color: #eaeef0;
}

.SectionHeader {
    font-size: 33px;
    font-family: "Roboto Condensed", sans-serif;
    color: #1f3b5e;
    padding: 48px 0 24px 24px;
}

.SectionHeader h6 {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #000;
}

.SectionContainer {
    margin: 1rem 1.25rem;
}

.Divider {
    width: 100%;
    height: 0.1px;
    margin: 0.25rem 0;
    background-color: #d6dbe0;
}

.CommitButton {
    color: white;
    background-color: #1f3b5e;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 12px;
    padding: 0.5rem 1rem;
    margin: 2rem 24px;
    border: none;
    text-transform: uppercase;
}

.CommitButton:hover {
    cursor: pointer;
}

.CommitButton:disabled {
    cursor: default;
    opacity: 0.5;
}

.Commitments {
    margin: 0 1rem;
}
.CommitmentsTable {
    margin: 0 8px;
    font-size: 15px;
}
.MobileCommitmentsTable {
    overflow-x: scroll;
    margin: 0 -1rem;
    table-layout: fixed;
    font-size: 12px;
}

.MobileCommitmentsTable tr > th {
    padding: 0.5rem 0.25rem;
    word-wrap: break-word;
}

.MobileCommitmentsTable td {
    font-family: "Roboto Condensed", sans-serif;
    color: #464646;
    padding: 0.5rem 0.25rem;
}

table {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(3, 29, 64, 0.14), 0 2px 1px -1px rgba(3, 29, 64, 0.12), 0 1px 3px 0 rgba(3, 29, 64, 0.2);
}

tr {
    border-bottom: 1px solid #d6dbe0;
}
tr > th {
    background-color: #f7f7f7;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    color: #1f1f1f;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

td {
    font-family: "Roboto Condensed", sans-serif;
    color: #464646;
    padding: 0.5rem 1rem;
}

td > a {
    color: #003e7e;
    text-decoration: underline;
}

.TabRow {
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #f7f7f7;
}
.TabHeader {
    width: 50%;
    text-align: center;
    vertical-align: middle;
    color: #1f1f1f;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 15px;
    padding: 0.5rem;
    text-transform: uppercase;
    border-right: 1px solid #d6dbe0;
}
.TabHeader_Active {
    color: #055eba;
    border-bottom: 3px solid #055eba;
}

.Filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
}

.Filter {
    margin: 0.25rem 0.75rem 0.25rem 0.5rem;
    height: 40px;
}

.Filter label {
    color: #1f1f1f;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    height: 100%;
    padding: 0.75rem;
    border-radius: 2px 0 0 2px;
    box-shadow: inset 0 -2px 0 0 #d6dbe0, inset 2px 0 0 0 #d6dbe0, inset 0 2px 0 0 #d6dbe0;
    background-color: #fafbfb;
    text-transform: uppercase;
}

.Filter select {
    color: #1f1f1f;
    height: 100%;
    width: auto;
    min-width: 150px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    border: 2px solid #d6dbe0;
    border-radius: 0 2px 2px 0;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
}

.MobileFilter {
    padding: 0.5rem;
    height: 4rem;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.MobileFilter label {
    color: #1f1f1f;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    padding: 0.75rem;
    width: 25%;
    height: 100%;
    border-radius: 2px 0 0 2px;
    box-shadow: inset 0 -2px 0 0 #d6dbe0, inset 2px 0 0 0 #d6dbe0, inset 0 2px 0 0 #d6dbe0;
    background-color: #fafbfb;
    text-transform: uppercase;
}

.MobileFilter select {
    color: #1f1f1f;
    height: 100%;
    width: 75%;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    border: 2px solid #d6dbe0;
    border-radius: 0 2px 2px 0;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
}

.SymbolKey {
    background-color: #f5f8fa;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 0.375rem -1rem;
    padding: 1rem 28px;
}
.SymbolHeader {
    color: #1f1f1f;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    font-weight: bold;
}
.Symbols {
    margin: 0 -0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.SymbolItem {
    padding: 0 0.5rem;
    border-right: 1px solid #d6dbe0;
    display: flex;
    flex-flow: row nowrap;
}
.SymbolItem:nth-last-of-type(1) {
    border-right: none;
}
.SymbolItem i {
    font-size: 14px;
    color: #e8222b;
    margin: 0.25rem 0.5rem 0.25rem 0;
}

.MobileSymbolItem {
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
}
.MobileSymbolItem i {
    font-size: 14px;
    color: #e8222b;
    margin: 0.25rem 0.5rem 0.25rem 0;
}

.FieldReportContainer {
    padding: 1rem;
    height: 100%;
    width: 100%;
}

.TournamentName {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 32px;
    color: #1f3b5e;
    text-transform: uppercase;
}

.Tooltip {
    position: relative;
    display: inline;
}

.TooltipText {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.Tooltip .TooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.Tooltip:hover .TooltipText {
    visibility: visible;
    opacity: 1;
}

.MobileCommitmentsTable .WithdrawInfo {
    text-align: center;
}

.WithdrawInfo {
    font-size: 15px;
    margin: 0;
    padding: 0 0 0.25rem 0;
    font-family: "Roboto", sans-serif;
    color: #000;
}

.WithdrawInfo p {
    margin-bottom: 0;
}

.Category {
    padding: 0.5rem 0;
    overflow-x: scroll;
}

.CategoryHeader {
    font-size: 14px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.FieldReportContainer {
    overflow-x: hidden;
}

.FieldReportContainer table,
.FieldReportContainer th,
.FieldReportContainer tr,
.FieldReportContainer td {
    background-color: inherit;
    border: none;
    box-shadow: none;
}

.PlayerNameLink {
    text-decoration: underline;
    color: #1f3b5e;
    cursor: pointer;
}

.FieldValues > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 200px;
}

.FieldValueName {
    text-align: left;
    width: 125px;
}

.ButtonLink {
    background: none;
    border: none;
    color: #003e7e;
    text-decoration: underline;
}

.ButtonLink:hover {
    color: #40a9ff;
    text-decoration: none;
}

.ButtonLink:focus {
    outline: none;
}

.Lighter {
    color: rgba(0, 0, 0, .45);
}

